

















import { Component, Prop, Vue } from 'vue-property-decorator';
import { INgo } from '@/models/contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import OptimizedImage from '@/components/common/optimized-image.vue';

@Component({
  components: { OptimizedImage }
})
export default class DetailIntro extends Vue {
  @Prop() readonly data!: INgo;

  private get description() {
    if (!this.data.fields.descriptionLong) {
      return null;
    }

    return documentToHtmlString(this.data.fields.descriptionLong);
  }
}
