


























import { Component, Prop, Vue } from 'vue-property-decorator';
import { IVideo } from '@/models/contentful';

@Component
export default class SocialVideo extends Vue {
  @Prop() readonly video!: IVideo;
}
