











import { Component, Prop, Vue } from 'vue-property-decorator';
import { INgo } from '@/models/contentful';
import SocialBoard from '@/components/social-board.vue';
@Component({
  components: { SocialBoard },
})
export default class DetailBoard extends Vue {
  @Prop() readonly data!: INgo;
  get showBlock() {
    return this.data.fields.boardMembers && this.data.fields.boardMembers.length > 0;
  }
}
