
































import { Component, Prop, Vue } from 'vue-property-decorator';
import { INgo } from '@/models/contentful';
import { prettyCategories, prettySizes } from '@/models/enums';

@Component
export default class DetailStats extends Vue {
  @Prop() readonly data!: INgo;

  private get prettyCategory() {
    if (!this.data.fields.category) {
      return null;
    }

    return prettyCategories[this.data.fields.category];
  }

  private get prettySize() {
    if (!this.data.fields.size) {
      return null;
    }

    return prettySizes[this.data.fields.size];
  }
}
