






import { Component, Prop, Vue } from 'vue-property-decorator';
import { INgo } from '@/models/contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

@Component
export default class DetailAdditional extends Vue {
  @Prop() readonly data!: INgo;

  private get additionalText() {
    if (!this.data.fields.additionalText) {
      return null;
    }

    return documentToHtmlString(this.data.fields.additionalText);
  }
}
