



















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { INgo } from '@/models/contentful';
import DetailCover from '@/components/detail-page/detail-cover.vue';
import { prettyCategories } from '@/models/enums';
import { Getter } from 'vuex-class';
import { Charity } from '@/models/store';
import OptimizedImage from '@/components/common/optimized-image.vue';

@Component({
  components: { DetailCover, OptimizedImage },
})
export default class OverviewCard extends Vue {
  @Getter private newCharities!: Charity[];
  @Prop() readonly data!: INgo;

  private get prettyCategory() {
    if (!this.data.fields.category) {
      return null;
    }

    return prettyCategories[this.data.fields.category];
  }

  private get isSupported() {
    if (this.newCharities.length === 0) {
      return false;
    }

    return this.newCharities.find(c => c.id === this.data.sys.id);
  }

  private changeSubscription() {
    this.$store.dispatch('toggleCharityInSubscription', {
      id: this.data.sys.id,
      name: this.data.fields.name,
    });
  }
}
