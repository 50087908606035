








import { Component, Prop, Vue } from 'vue-property-decorator';
import { INgo } from '@/models/contentful';
import OptimizedImage from '@/components/common/optimized-image.vue';

@Component({
  components: { OptimizedImage },
})
export default class DetailCover extends Vue {
  @Prop() readonly data!: INgo;
  @Prop({ default: false }) readonly detailPage!: boolean;
}
