class ImageUtils {
  public getImgUrl(
    url: string,
    width: number | null = null,
    height: number | null = null,
    webp = false,
    bgColor: string | null = null
  ) {
    let qs = `fm=${webp ? 'webp' : 'jpg'}&q=90`;

    if (width) {
      qs += `&w=${width}`;
    }

    if (height) {
      qs += `&h=${height}`;
    }

    if (bgColor) {
      qs += `&fit=pad&bg=rgb:${bgColor}`;
    }

    return `${url}?${qs}`;
  }

  public canUseWebP() {
    if (typeof document === 'undefined') {
      return false;
    }

    const el = document.createElement('canvas');
    if (el.getContext && el.getContext('2d')) {
      return el.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    }

    return false;
  }
}

export default new ImageUtils();
