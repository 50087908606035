




















import { Component } from 'vue-property-decorator';
import { contentfulService } from '@/services';
import { INgo } from '@/models/contentful';
import SocialVideo from '@/components/social-video.vue';
import OptimizedImage from '@/components/common/optimized-image.vue';
import SocialBoard from '@/components/social-board.vue';
import DetailCover from '@/components/detail-page/detail-cover.vue';
import DetailIntro from '@/components/detail-page/detail-intro.vue';
import DetailStats from '@/components/detail-page/detail-stats.vue';
import DetailPictures from '@/components/detail-page/detail-pictures.vue';
import DetailAdditional from '@/components/detail-page/detail-additional.vue';
import DetailBoard from '@/components/detail-page/detail-board.vue';
import DetailDonate from '@/components/detail-page/detail-donate.vue';
import Loader from '@/components/common/loader.vue';
import AuthMixin from '../auth/auth-mixin';
import PageNotFound from '@/components/page-not-found.vue';

@Component({
  components: {
    Loader,
    DetailDonate,
    DetailBoard,
    DetailPictures,
    DetailStats,
    DetailIntro,
    DetailCover,
    SocialBoard,
    SocialVideo,
    DetailAdditional,
    OptimizedImage,
    PageNotFound,
  },
})
export default class CharityDetailPage extends AuthMixin {
  private data: INgo | null = null;
  private loading = true;

  async created() {
    this.data = await contentfulService.fetchNgoBySlug(this.$route.params.id);
    this.loading = false;
  }
}
