import { Component, Vue, Watch } from 'vue-property-decorator';
import { getUser, observeUser } from '@/firebase-app';
import { Getter, Mutation } from 'vuex-class';
import { socialService } from '@/services';

@Component
export default class AuthMixin extends Vue {
  @Mutation private setInitiated!: () => void;
  @Getter private isProfileComplete!: boolean;
  @Getter protected isInitiated!: boolean;
  private redirectTo: string | null = null;
  private allFieldsValid = false;

  public created() {
    observeUser(async user => {
      if (user) {
        const profileResponse = await socialService.getUserProfile();
        if (profileResponse) {
          await this.$store.dispatch('handleProfileResponse', profileResponse);
        }
      }

      this.setInitiated();
    });
  }

  @Watch('isInitiated', { immediate: true })
  async dunno() {
    const user = getUser();

    if (!this.isInitiated) {
      return;
    }

    // First make sure we save the redirect part
    if (this.$route.query?.redirectTo && !Array.isArray(this.$route.query?.redirectTo.length)) {
      this.redirectTo = this.$route.query.redirectTo as string;
    }

    // No user - Redirect to login
    if (!user && this.$route.path.includes("/profiel")) {
      return this.$router.push({ name: 'LoginPage', query: { redirectTo: this.redirectTo } });
    }

    // Check if we have all fields
    this.allFieldsValid = this.isProfileComplete;

    // Missing fields - Redirect to complete page
    if (user && this.allFieldsValid === false) {
      if (!this.redirectTo) {
        this.redirectTo = this.$route.name + '';
      }

      await this.$router.push({
        name: 'CompleteAccountPage',
        query: { redirectTo: this.redirectTo },
      });
    }
  }
}
