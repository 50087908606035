var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.video)?_c('section',{staticClass:"SocialVideo"},[(_vm.video.fields.youtubeId)?_c('youtube',{attrs:{"video-id":_vm.video.fields.youtubeId,"player-vars":{
      modestbranding: 1,
      showinfo: 0,
      controls: 0,
      disablekb: 1,
      fs: 0,
      hl: 'nl',
      iv_load_policy: 3,
      rel: 0,
    }}}):_vm._e(),(_vm.video.fields.vimeoId)?_c('vimeo-player',{staticClass:"video-wrapper",attrs:{"video-id":_vm.video.fields.vimeoId}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }