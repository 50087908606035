



















































import { Component, Vue, Watch } from 'vue-property-decorator';
import { INgo } from '@/models/contentful';
import { contentfulService } from '@/services';
import DetailCover from '@/components/detail-page/detail-cover.vue';
import DetailIntro from '@/components/detail-page/detail-intro.vue';
import DetailStats from '@/components/detail-page/detail-stats.vue';
import Loader from '@/components/common/loader.vue';
import OverviewCard from '@/components/overview-page/overview-card.vue';
import { Charity } from '@/models/store';
import { Getter } from 'vuex-class';
import { prettyCategories, prettySizes } from '@/models/enums';
import AuthMixin from '../auth/auth-mixin';

@Component({
  components: { OverviewCard, Loader, DetailStats, DetailIntro, DetailCover },
})
export default class CharitiesOverview extends AuthMixin {
  @Getter private currentCharities!: Charity[];
  private data: INgo[] | null = null;
  private allNgos: INgo[] | null = null;
  private loading = true;
  private prettyCategories = prettyCategories;
  private prettySizes = prettySizes;
  private selectedThemeFilter: string | null = null;
  private selectedSizeFilter: string | null = null;

  async created() {
    // Get the data...
    this.data = await contentfulService.fetchNGOs();
    if (this.data) {
      this.allNgos = [...this.data];
      this.personalizeAndFilterResults();
    }

    // Set the filters
    const qs = this.$route.query;
    if (qs && qs?.theme && !Array.isArray(qs.theme)) {
      this.selectedThemeFilter = prettyCategories[qs.theme] ? qs.theme : null;
    }
    if (qs && qs?.size && !Array.isArray(qs.size)) {
      this.selectedSizeFilter = prettySizes[qs.size] ? qs.size : null;
    }

    // All done, show page
    this.loading = false;
  }

  private resetFilters() {
    this.selectedThemeFilter = null;
    this.selectedSizeFilter = null;
  }

  @Watch('selectedSizeFilter')
  private handleSelectedSizeFilterChange() {
    this.personalizeAndFilterResults();
    this.changeRouteAfterFilter();

    this.$gtag.event('change-filter', {
      event_category: 'size',
      event_label: 'value',
      value: this.selectedSizeFilter,
    });
  }

  @Watch('selectedThemeFilter')
  private handleSelectedThemeFilterChange() {
    this.personalizeAndFilterResults();
    this.changeRouteAfterFilter();

    this.$gtag.event('change-filter', {
      event_category: 'theme',
      event_label: 'value',
      value: this.selectedThemeFilter,
    });
  }

  @Watch('currentCharities')
  private handleCharitiesChange() {
    this.personalizeAndFilterResults();
  }

  private changeRouteAfterFilter() {
    let query = '';
    if (this.selectedThemeFilter) {
      query += `theme=${this.selectedThemeFilter}`;
    }
    if (this.selectedSizeFilter) {
      query += `size=${this.selectedSizeFilter}`;
    }

    const url = query ? `?${query}` : this.$route.path;
    if (location.search !== url) {
      history.pushState('', '', url);
    }
  }

  private personalizeAndFilterResults() {
    if (!this.data || !this.allNgos) {
      return;
    }

    // First apply filter
    const filteredNgos = this.allNgos.filter(n => {
      const matchesTheme = this.selectedThemeFilter === null || n.fields.category === this.selectedThemeFilter;
      const matchesSize = this.selectedSizeFilter === null || n.fields.size === this.selectedSizeFilter;
      return matchesTheme && matchesSize;
    });

    if (!this.currentCharities) {
      this.data = [...filteredNgos];
      return;
    }

    const currentCharityIds = this.currentCharities.map((c: Charity) => c.id);
    const movableCharities = filteredNgos.filter(c => currentCharityIds.indexOf(c.sys.id) !== -1);

    movableCharities.forEach(mc => {
      // @ts-ignore
      const index = filteredNgos.indexOf(mc);
      if (index >= 0) {
        // @ts-ignore
        filteredNgos.splice(index, 1);
      }
    });

    this.data = [...movableCharities, ...filteredNgos];
  }
}
