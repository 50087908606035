export type Dictionary = { [index: string]: string };

export const prettyCategories: Dictionary = {
  natureAndEnvironment: 'Natuur & Milieu',
  health: 'Gezondheid',
  welfare: 'Welzijn',
  internationalAidAndHumanRights: 'Internationale hulp & Mensenrechten',
  animals: 'Dieren',
  educationAndScience: 'Onderwijs & Wetenschap',
};

export const prettySizes: Dictionary = {
  klein: '0 tot 10 mensen',
  middel: '10 tot 50 mensen',
  groot: '> 50 mensen',
};
