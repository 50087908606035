
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { INgo } from '@/models/contentful';
import SocialVideo from '@/components/social-video.vue';
import OptimizedImage from '@/components/common/optimized-image.vue';
@Component({
  components: { OptimizedImage, SocialVideo },
})
export default class DetailPictures extends Vue {
  @Prop() readonly data!: INgo;

  get showBlock() {
    return (
      (this.data.fields.video && this.data.fields.video.length > 0) ||
      (this.data.fields.mediaList && this.data.fields.mediaList.length > 0)
    );
  }
}
