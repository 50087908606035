













import { Component, Prop, Vue } from 'vue-property-decorator';
import { INgo } from '@/models/contentful';
import { Getter } from 'vuex-class';
import { Charity } from '@/models/store';

@Component
export default class DetailDonate extends Vue {
  @Getter private newCharities!: Charity[];
  @Prop() readonly data!: INgo;

  private get isSupported() {
    if (this.newCharities.length === 0) {
      return false;
    }

    return this.newCharities.find(c => c.id === this.data.sys.id);
  }

  private changeSubscription() {
    this.$store.dispatch('toggleCharityInSubscription', {
      id: this.data.sys.id,
      name: this.data.fields.name,
    });
  }
}
