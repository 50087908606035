












import { Component, Prop, Vue } from 'vue-property-decorator';
import { IBoardMember } from '@/models/contentful';
import OptimizedImage from '@/components/common/optimized-image.vue';
@Component({
  components: { OptimizedImage },
})
export default class SocialBoard extends Vue {
  @Prop() readonly boardMember!: IBoardMember;
}
