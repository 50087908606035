








import { Component, Prop, Vue } from 'vue-property-decorator';
import ImageUtils from '@/utils/image-utils';

@Component
export default class OptimizedImage extends Vue {
  @Prop() readonly src!: string;
  @Prop() readonly width!: number;
  @Prop() readonly height!: number;
  @Prop() readonly bgColor!: string;

  get webpUrl() {
    return this.imageUrl(true);
  }

  get pngUrl() {
    return this.imageUrl(false);
  }

  imageUrl(webp: boolean) {
    return ImageUtils.getImgUrl(this.src, this.width, this.height, webp, this.bgColor);
  }
}
